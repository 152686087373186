import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 20 min. `}<meta itemProp="prepTime" content="PT20M" /></h5>
    <h5>{`Cook time: 60-90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  1 large eggplant or 4 servings of chicken cuts (thighs, breast, tenders)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bread crumbs, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan cheese, 1/8 cup shredded</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Smoked paprika, 1/8 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic powder, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, a dash</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Basil, a dash</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, a dash</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Thyme, a dash</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Rosemary, a dash</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, a dash</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">
  Baking sheet for eggplant, 8x8 glass pan for chicken
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Parchment paper for eggplant</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">2 small mixing bowls</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Melt butter in a small bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Thaw chicken, if applicable</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`For eggplant: wash and trim eggplant. Slice into 1/2 inch disks. Sprinkle with salt, let sit for 30 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix bread crumbs, parmesan, and all spices in another small bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Dip each eggplant/chicken piece into the butter, let drip, then dip into the breading mix until coated. Arrange on baking pan/sheet.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake eggplant at 400F for 20-25 minutes, chicken at 350F for 1 hour, until coating is golden brown and crispy.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Let chicken rest for 10 minutes before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      